





















import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'MOrganizers',
  components: {
    MManageAccess: () => import('@/components/molecules/settings/m-manage-access.vue')
  },
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    programId: {
      required: true,
      type: String,
      default: ''
    },
    isDraft: {
      type: Boolean,
      required: false
    }
  },
  setup(props, ctx: any) {
    const dialogManageAccess = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    function closeModal() {
      dialogManageAccess.value = false;
    }

    return {
      dialogManageAccess,
      closeModal
    };
  }
});
